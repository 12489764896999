
export default {
    name: "VideoUploadAlert",
    props: {
        running: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
            validator: (val) => ["ratio", "format"].includes(val),
        },
    },
    data: () => ({
        errors: {
            ratio: {
                header: "Das Video hat das falsche Seitenverhältnis",
                description:
                    "Um bestmöglich auf unserer Webseite dargestellt zu werden sollte ein Video ein Seitenverhältnis zwischen 3:4 und 9:16 (hochkant) haben.",
                request: "Versuchen Sie die Seite neu zu laden",
            },
            format: {
                header: "Diese Datei hat kein gültiges Format",
                description:
                    "Oabat unterstützt folgende Videoformate: .mp4, .avi, .mov, .wmv, .avi, .flv.",
                request: "Bitte konvertieren Sie gegebenenfalls die Datei",
            },
        },
    }),
    computed: {
        currentError() {
            return this.errors[this.error] ?? null
        },
    },
}
